<div class="view-container">
    <div class="header">
        <span class="title"><strong>Chave:</strong> {{ title }}</span>
        <a routerLink="/" class="close-view"><strong>Voltar</strong></a>
    </div>
    <div class="context" *ngIf="!error; else tmpError" lazy-load-images>
        <figure class="lazy-load-page" *ngFor="let page of pages">
            <img src="/assets/img/img-loading.gif" [attr.data-src]="page.url">
            <figcaption *ngIf="figcaption">( pagina {{ page.page }} de {{ total_page }} )</figcaption>
        </figure>
    </div>
    <ng-template #tmpError>
        <div class="code-error">{{ error }}</div>
    </ng-template>
</div>
